/* globals jQuery, wordpress_globals */
import "../sass/theme.scss";
import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
// @ts-ignore
import svgxhr from "webpack-svgstore-plugin/src/helpers/svgxhr";
import AnimateScroll from "./modules/AnimateScroll";
// Prefer creating new modules when appropriate, rather than adding functions here
// If you need something to be globally accessible, you can assign an imported module to this namespace
// I.E import Contact from './contact';
// window.namespace.Contact = Contact;
const bootstrap = {
    init: function () {
        this.helpers.initSvg();
        const animateScroll = new AnimateScroll();
        animateScroll.init();
        //Allow user to interrupt auto-scrolling
        $(document).bind('scroll mousedown wheel keyup', function (e) {
            if ((e.which || e.type === 'mousedown' || e.type === 'mousewheel') && document.scrollingElement) {
                $(document.scrollingElement).stop();
            }
        });
    },
    helpers: {
        requireAll: function (r) {
            r.keys().forEach(r);
        },
        initSvg: function () {
            svgxhr({
                filename: './wp-content/themes/class/dist/sprite.svg', //URL to fetch
            });
        }
    }
};
document.addEventListener("DOMContentLoaded", () => {
    bootstrap.init();
});
// SVG Sprite Loader
bootstrap.helpers.requireAll(require.context('../images/icons/', true, /\.svg$/));
