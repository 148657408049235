/* eslint-disable max-len,no-plusplus,no-param-reassign */
import Scroll from '../lib/scroll';
class AnimateScroll extends Scroll {
    constructor() {
        super();
    }
    init() {
        var _a;
        ((_a = document.querySelectorAll('[href^="#"]')) !== null && _a !== void 0 ? _a : []).forEach((link) => {
            link.addEventListener('click', this.initScroll.bind(this));
        });
        window.addEventListener('animate.scroll', this.animateEvent.bind(this));
    }
    animateEvent(ev) {
        const event = (ev);
        let element;
        element = event.detail;
        if (typeof element === 'undefined') {
            return false;
        }
        if (!(element instanceof HTMLElement)) {
            element = document.querySelector(element);
        }
        if (element !== null) {
            this.scroll(element, 1250);
        }
        return true;
    }
    initScroll(ev) {
        var _a;
        if (!ev.target)
            return;
        const element = ev.currentTarget;
        if (typeof element.getAttribute('href') === 'undefined' ||
            element.getAttribute('href') === '#') {
            console.warn('[AnimateScroll] Href attribute undefined');
            return;
        }
        const querySel = (_a = element.getAttribute('href')) !== null && _a !== void 0 ? _a : "";
        if (document.querySelector(querySel) === null) {
            console.warn(`[AnimateScroll] Referenced element does not exist - ${element.getAttribute('href')}`);
            return;
        }
        const toElement = document.querySelector(querySel);
        if (!toElement) {
            return;
        }
        ev.preventDefault();
        this.scroll(toElement, 1250);
    }
}
export default AnimateScroll;
